/* eslint-env browser */
import {DateTime, Duration} from "luxon";
import queryString from "qs";

const MIN_LOCK_TIMER_DURATION = Duration.fromObject({seconds: 15});

/* @ngInject */
export default class InboxItemService {
  constructor($injector) {
    this._backendConfig = $injector.get("backendConfig");
    this._$rootScope = $injector.get("$rootScope");
    this._$state = $injector.get("$state");
    this._$http = $injector.get("$http");
    this._$mdDialog = $injector.get("$mdDialog");
    this._Authentication = $injector.get("Authentication");
    this._EventService = $injector.get("EventService");
    this._FacilityService = $injector.get("Facility");
    this._ActionService = $injector.get("ActionService");
    this._UserService = $injector.get("UserService");
    this._StripService = $injector.get("StripService");
    this._ChannelConnectionService = $injector.get("ChannelConnectionService");
    this._Lock = $injector.get("Lock");
    this._Session = $injector.get("Session");
    this._ReportService = $injector.get("ReportService");
    this._NotificationService = $injector.get("NotificationService");
    this._GeneratedReportService = $injector.get("GeneratedReportService");
    this._ScrollService = $injector.get("ScrollService");
    this._Search = $injector.get("SearchService");
    this._Markers = $injector.get("Markers");

    this.features = $injector.get("Config").features;

    this.listCounts = {};
    this._selectedItem = null;
    this._selectingItem = false;
    this._timerForRenewingLock = null;

    this.INBOX_ITEM_TYPES = [
      "dailyTrend",
      "summary",
      "baseline",
      "ecgDataRequest",
      "patientActivated",
      "rhythmChange",
      "rateChange",
      "notification",
    ];
  }

  get selectedItem() {
    return this._selectedItem;
  }

  get isSelectingItem() {
    return this._selectingItem;
  }

  isItemSelected(item) {
    return (
      this._selectedItem &&
      this._selectedItem.type === item.type &&
      this._selectedItem.id === item.id &&
      !!this._selectedItem.isNextAvailable === !!item.isNextAvailable
    );
  }

  async _selectAndLoadItem(item) {
    try {
      this._selectingItem = true;

      let lockedByThisSession = false;
      if (item.locked) {
        await this._loadLockedItem(item);
      } else if (item.assignedToOtherUser) {
        await this._loadItemAssignedToOtherUser(item);
      } else if (item.loadedByThisSession) {
        // If the item is loaded by this session, just renew the lock
        await this._Lock.lockItem(item);
        lockedByThisSession = true;
      } else {
        await Promise.all([this._Lock.lockItem(item), this.loadItem(item)]);
        lockedByThisSession = true;
      }

      this._selectedItem = item;
      this._selectingItem = false;

      const expirationTime = DateTime.fromISO(item.lockExpiration);
      const now = DateTime.now();
      if (lockedByThisSession && expirationTime.isValid && expirationTime > now) {
        const msBeforeRenewingLock = Math.max(
          Math.floor((expirationTime - now) / 2),
          MIN_LOCK_TIMER_DURATION.valueOf()
        );

        if (this._timerForRenewingLock !== null) {
          clearTimeout(this._timerForRenewingLock);
        }

        // Halfway between now and the lock expiration time, renew the item lock
        this._timerForRenewingLock = setTimeout(async () => {
          this._timerForRenewingLock = null;

          if (this._selectedItem) {
            try {
              await this._selectAndLoadItem(this._selectedItem);
            } catch (err) {
              console.error(`Failed to renew item lock:`, err);
            }
          }
        }, msBeforeRenewingLock);
      }
    } catch (err) {
      // If item failed to open because it's already locked, set it to locked and open it as a locked item
      if (err.status === 423) {
        item.lockedAt = new Date().toISOString();
        await this._selectAndLoadItem(item);
      } else {
        // Something else failed while opening the item, so display an error
        this._selectingItem = false;

        this._$mdDialog.show(
          this._$mdDialog
            .alert()
            .title("Failed to open item")
            .htmlContent(
              `<p class="warningMessage"><i class="material-icons dialogErrorIcon"> error </i> ` +
                `An error occurred while opening an item. Please try again.</p>` +
                `<p class="warningMessage"> If this issue persists, contact support.</p>`
            )
            .ok("Ok")
        );

        throw err;
      }
    }

    return this._selectedItem;
  }

  async selectItem(item, autoScroll = true) {
    try {
      this._selectingItem = true;
      const clickedItemIsSelected = this.isItemSelected(item);

      if (clickedItemIsSelected) {
        await this.deselectItem();
        this._selectingItem = false;
      } else {
        // Scroll before and after deselecting the previous item so that large scroll
        // distances aren't disrupted by closing the item
        if (autoScroll) {
          this._ScrollService.scrollToElement(item.id);
        }
        await this.deselectItem();

        if (autoScroll) {
          setTimeout(() => this._ScrollService.scrollToElement(item.id), 100);
        }
        await this._selectAndLoadItem(item);
      }
    } catch (err) {
      this._selectingItem = false;
      throw err;
    }

    return this._selectedItem;
  }

  async deselectItem(withECGNull = true) {
    const oldItem = this._selectedItem;
    this._selectedItem = null;
    if (this._timerForRenewingLock !== null) {
      clearTimeout(this._timerForRenewingLock);
      this._timerForRenewingLock = null;
    }

    await this._Lock.unlockItem();
    if (oldItem?.ecg && withECGNull) {
      oldItem.ecg = null;
    }

    this._$rootScope.$emit("inbox-item-deselected", oldItem);

    return this._selectedItem;
  }

  instantiateItem(item) {
    let instantiatedItem;
    item.locking = false;
    item.unlocking = false;
    item.loading = false;
    item.lockedByUserFullName = null;

    if (item.studyNotes) {
      try {
        item.studyNotes = JSON.parse(item.studyNotes);
      } catch (error) {
        // do nothing
      }
    }

    // Un-flatten the item's study so that nested React components can have access to it
    item.study = {
      id: item.studyId,
      currentEnrollment: item.allEnrollments[0],
      createdAt: item.studyCreationDate,

      // equivalent fields
      allEnrollments: item.allEnrollments,
      configuredDuration: item.configuredDuration,
      dataReceived: item.dataReceived,
      enrollmentId: item.enrollmentId,
      facilityId: item.facilityId,
      insurance: item.insurance,
      itemCount: item.itemCount,
      recordedDuration: item.recordedDuration,
      reportCount: item.reportCount,
      studyEndDate: item.studyEndDate,
      studyId: item.studyId,
      studyIndication: item.studyIndication,
      studyNotes: item.studyNotes,
      studyStartDate: item.studyStartDate,
      studyState: item.studyState,
      studyType: item.studyType,
      tzSerial: item.tzSerial,
      timeZone: item.timeZone,
      orderNumber: item.orderNumber,
      downgradeAuthorized: item.downgradeAuthorized,

      facility: {
        id: item.facilityId,
        name: item.facilityName,
      },

      studyDetails: {
        patientAddress: item.patientAddress,
        patientDob: item.patientDob,
        patientAge: item.patientAge,
        patientGender: item.patientGender,
        patientHeight: item.patientHeight,
        patientId: item.patientId,
        patientLanguage: item.patientLanguage,
        patientName: item.patientName,
        patientNotes: item.patientNotes,
        patientPhoneNumber: item.patientPhoneNumber,
        patientWeight: item.patientWeight,
        physicianAddress: item.physicianAddress,
        physicianEmail: item.physicianEmail,
        physicianFacility: item.physicianFacility,
        physicianName: item.physicianName,
        physicianNotes: item.physicianNotes,
        physicianNpiNumber: item.physicianNpiNumber,
        physicianPhoneNumber: item.physicianPhoneNumber,
        physicianType: item.physicianType,
      },

      createdByUser: {
        fullName: item.studyCreatedBy,
      },
    };

    if (this._ReportService.getReportTypes().includes(item.type)) {
      instantiatedItem = this._ReportService.instantiateReport(item);
    } else if (
      this._NotificationService.getNotificationTypes().includes(item.type) ||
      this._NotificationService.getUnsupportedNotificationTypes().includes(item.type)
    ) {
      instantiatedItem = this._NotificationService.instantiateNotification(item);
    } else {
      instantiatedItem = this._EventService.instantiateEvent(item);
    }
    return instantiatedItem;
  }

  complete(item = this._selectedItem) {
    return item.complete().then(() => this.deselectItem());
  }

  getItems(queryParams, isTriage = false, headers = {}) {
    queryParams.flatten = true;
    const url = isTriage ? "/triageItems" : "/inboxItems";
    return this._httpGet(url, queryParams, {"tzmedical-br-origin": "service-get-items", ...headers})
      .then((response) => {
        return {
          items: response.data.map((item) => this.instantiateItem(item)),
          totalCount: response.headers("count"),
        };
      })
      .catch((error) => {
        // If we're logging out, this service might try to make requests after the JWT is deleted.
        if (error.status === 401) {
          return {items: []};
        }
        throw error;
      });
  }

  getItemById(id, headers) {
    const url = "/inboxItems";
    const queryParams = {
      id,
      completed: {$or: [true, false]},
      flatten: true,
    };
    return this._httpGet(url, queryParams, {"tzmedical-br-origin": "service-get-item-by-id", ...headers})
      .then((response) => {
        if (response.data[0]) {
          return this.instantiateItem(response.data[0]);
        }
        return null;
      })
      .catch((error) => {
        // If we're logging out, this service might try to make requests after the JWT is deleted.
        if (error.status === 401) {
          return null;
        }
        throw error;
      });
  }

  /*
   * @see SRS: BR-1554, BR-1552
   */
  getNextInboxItemForReview(params = null, isTriage = false) {
    const url = isTriage ? "/triageItems/next" : "/inboxItems/next";

    // Attach user and session to query for locking the item
    params.sessionId = this._Session.sessionId;
    params.flatten = true;

    return this._httpGet(url, this._Search.convertSearchToQueryParams(params), {
      "tzmedical-br-origin": "service-get-next-item",
    }).then((response) => {
      const item = response.data;

      return this.instantiateItem(item);
    });
  }

  async updateItem(updatedItem, itemToUpdate) {
    const propertiesToUpdate = this._getPropertiesToUpdateOnPubsub(updatedItem);

    if (this._isSelectedItemUnlocking(updatedItem, itemToUpdate)) {
      try {
        if (this._Session.sessionId !== itemToUpdate.sessionId) {
          await Promise.all([this.loadItem(itemToUpdate), this._Lock.lockItem(itemToUpdate)]);
        } else {
          await this._Lock.lockItem(itemToUpdate);
        }

        this._selectedItem = itemToUpdate;
        this._selectingItem = false;

        const expirationTime = DateTime.fromISO(itemToUpdate.lockExpiration);
        const now = DateTime.now();
        if (expirationTime.isValid && expirationTime > now) {
          const msBeforeRenewingLock = Math.max(
            Math.floor((expirationTime - now) / 2),
            MIN_LOCK_TIMER_DURATION.valueOf()
          );

          if (this._timerForRenewingLock !== null) {
            clearTimeout(this._timerForRenewingLock);
          }

          // Halfway between now and the lock expiration time, renew the item lock
          this._timerForRenewingLock = setTimeout(async () => {
            this._timerForRenewingLock = null;

            if (this._selectedItem) {
              try {
                await this._selectAndLoadItem(this._selectedItem);
              } catch (err) {
                console.error(`Failed to renew item lock:`, err);
              }
            }
          }, msBeforeRenewingLock);
        }
      } catch (err) {
        // max locks already reached (this could happen if user has 2 tabs open and switches to a different event on another tab)
        if (err.status === 403) {
          await this.deselectItem();
          this._updateItemProperties(updatedItem, itemToUpdate, propertiesToUpdate);
        }
        // If item failed to open because it's already locked, set it to locked and open it as a locked item
        else if (err.status === 423) {
          itemToUpdate.lockedAt = new Date().toISOString();
          await this._selectAndLoadItem(itemToUpdate);
        }
      }
    } else {
      this._updateItemProperties(updatedItem, itemToUpdate, propertiesToUpdate);
    }

    return itemToUpdate;
  }

  _getPropertiesToUpdateOnPubsub(updatedItem) {
    let propertiesToUpdate = [
      "details",
      "userClassification",
      "lockedAt",
      "lockedBy",
      "sessionId",
      "completed",
      "assignedUsers",
    ];

    // Notification controller renames certain "updateSettings" actions
    // to "convertMctToCem". The actions are stored in notification.details, and
    // we don't want to update the actions back to "updateSettings"
    if (updatedItem.type === "Study Action Failed") {
      propertiesToUpdate = propertiesToUpdate.filter((prop) => prop !== "details");
    }

    return propertiesToUpdate;
  }

  _isSelectedItemUnlocking(updatedItem, itemToUpdate) {
    const itemToUpdateIsSelected = this.isItemSelected(itemToUpdate);
    const updateUnlocksItem = !updatedItem.lockedAt && !!itemToUpdate.lockedAt;

    const updateUnassignsItem = !this.features.itemAssignments || updatedItem.assignedUsers?.length === 0;
    const updateAssignsItemToUser = !!updatedItem.assignedUsers?.find(
      ({userId}) => userId === this._Authentication.getUserId()
    );

    return updateUnlocksItem && itemToUpdateIsSelected && (updateUnassignsItem || updateAssignsItemToUser);
  }

  _updateItemProperties(updatedItem, itemToUpdate, propertiesToUpdate) {
    propertiesToUpdate.forEach((prop) => {
      itemToUpdate[prop] = updatedItem[prop];
    });
  }

  async loadItem(item) {
    const ecgPromise = this._getEcgIfNeeded(item);
    const savedStripsPromise = this._getSavedStripsIfNeeded(item);
    const channelConnectionPromise = this._getChannelConnectionsIfNeeded(item);
    const beatMarkersPromise = this._getBeatMarkersIfNeeded(item);
    const requireReportPromise = this._getRequireReportIfNeeded(item);
    let settingsPromise = Promise.resolve();
    let singleEpisodeReportsPromise = Promise.resolve();

    if (!["holter", "extendedHolter"].includes(item.studyType)) {
      // Get latest study settings here (not setting when item was recorded) so that the item
      // lists up-to-date study duration and arrhythmia thresholds
      settingsPromise = this._ActionService.getLatestSettings(item.enrollmentId, item.studyEndDate);
    }

    if (!item.isTriageItem) {
      singleEpisodeReportsPromise = this._getSerIfNeeded(item);
    }

    const [
      ecg,
      savedStrips,
      channelConnectionResults,
      beatMarkers,
      requireReport,
      latestSettings,
      singleEpisodeReports,
    ] = await Promise.all([
      ecgPromise,
      savedStripsPromise,
      channelConnectionPromise,
      beatMarkersPromise,
      requireReportPromise,
      settingsPromise,
      singleEpisodeReportsPromise,
    ]);

    item.ecg = item.ecg || ecg;
    this._setSavedStrips(item, savedStrips);
    item.connectionHistory = channelConnectionResults.connectionHistory;
    item.activeChannels = channelConnectionResults.activeChannels;
    item.beatMarkers = beatMarkers;
    item.requireReport = requireReport;

    // Attach the settings fields that are utilized within the item
    if (latestSettings) {
      item.tachyBpm = latestSettings.tachyBpm;
      item.bradyBpm = latestSettings.bradyBpm;

      if (item.configuredDuration) {
        item.studyDays = Math.floor(item.configuredDuration / 24);
      } else {
        item.studyDays = latestSettings.studyDays;
      }
    }

    if (singleEpisodeReports) {
      item.singleEpisodeReports = singleEpisodeReports;
    }

    item.loadedByThisSession = true;
    return item;
  }

  async _loadLockedItem(item) {
    const lockedByFullNamePromise = this._getUserFullName(item.lockedBy);
    const promises = [lockedByFullNamePromise];

    if (!["holter", "extendedHolter"].includes(item.studyType)) {
      // Get latest study settings here (not setting when item was recorded) so that the item
      // lists up-to-date study duration and arrhythmia thresholds
      const settingsPromise = this._ActionService.getLatestSettings(item.enrollmentId, item.studyEndDate);
      promises.push(settingsPromise);
    }

    const [lockedByUserFullName, latestSettings] = await Promise.all(promises);
    item.lockedByUserFullName = lockedByUserFullName;

    if (latestSettings) {
      item.studyDays = latestSettings.studyDays;
      item.tachyBpm = latestSettings.tachyBpm;
      item.bradyBpm = latestSettings.bradyBpm;
    }

    return item;
  }

  async _loadItemAssignedToOtherUser(item) {
    if (!["holter", "extendedHolter"].includes(item.studyType)) {
      // Get latest study settings here (not setting when item was recorded) so that the item
      // lists up-to-date study duration and arrhythmia thresholds
      const latestSettings = await this._ActionService.getLatestSettings(
        item.enrollmentId,
        item.studyEndDate
      );
      item.studyDays = latestSettings.studyDays;
      item.tachyBpm = latestSettings.tachyBpm;
      item.bradyBpm = latestSettings.bradyBpm;
    }

    return item;
  }

  _setSavedStrips(item, strips) {
    let includeInReport;

    // On EcgEvents, strips are stored in 2 arrays
    if (item.category === "ECG Event") {
      // Event Strips
      item.eventStrips = strips.filter((strip) => strip.eventId === item.id);

      // Non-event strips (Toggled off by Default)
      item.listedStrips = strips.filter((strip) => strip.eventId !== item.id);
      includeInReport = false;
    } else {
      // On Report Items, Strips are Toggled on by Default
      item.listedStrips = strips;
      includeInReport = true;
    }

    strips.forEach((strip) => {
      strip.includeInReport = includeInReport;
      strip.isPatientActivated = strip.deviceClassification === "Patient Activated Event";

      // Convert array of stripMeasurements into measurements object (per type)
      strip.measurements = this._Markers.DEFAULT_MEASUREMENTS;
      strip.stripMeasurements?.forEach((measurement) => {
        strip.measurements[measurement.name].data.push(measurement);
      });
      delete strip.stripMeasurements;

      this._Markers.MEASUREMENT_TYPES.forEach((measurementType) => {
        this._Markers.updateMinMeanMaxForStrip(measurementType, strip);
      });
    });

    // Sort Strips From Oldest to Newest
    item.listedStrips.sort((a, b) => (a.startTime < b.startTime ? -1 : 1));
  }

  async _getEcgIfNeeded(item) {
    let ecg = null;

    // if it's an ECG event and does not have an ecg yet
    if (item.category === "ECG Event" && !item.ecg) {
      const eventType = this._EventService.getEcgEventType(item.type);
      ({ecg} = await this._EventService.getEventAndEcg(eventType, item.id));
    }

    return ecg;
  }

  _getBeatMarkersIfNeeded(item) {
    if (item.category === "ECG Event") {
      const eventType = this._EventService.getEcgEventType(item.type);
      const params = {
        eventId: item.id,
        eventType,
      };
      return this._Markers.getEventBeatMarkers(params);
    }
    return Promise.resolve([]);
  }

  _getSavedStripsIfNeeded(item) {
    const studyTypesWithStrips = ["mct", "cem", "mctWithFullDisclosure"];
    // if it's a report item, mct/cem study, and no saved strips yet
    if (item.category === "Report" && studyTypesWithStrips.includes(item.studyType)) {
      // Defaults for Daily Trend (starting 24 hours prior to trend timestamp)
      let startStripPeriod = DateTime.fromISO(item.timestamp)
        .minus({days: 1})
        .toUTC()
        .toFormat("yyyy-MM-dd HH:mm:ss");
      const endStripPeriod = DateTime.fromISO(item.timestamp).toUTC().toFormat("yyyy-MM-dd HH:mm:ss");
      if (item.type === "Summary") {
        startStripPeriod = DateTime.fromISO(item.studyStartDate).toUTC().toFormat("yyyy-MM-dd HH:mm:ss");
      }
      // Fetch strips in the time period plus any Baseline strips
      const params = {
        studyId: item.studyId,
        $or: [
          {$and: [{startTime: {$lte: endStripPeriod}}, {userClassification: "Baseline"}]},
          {startTime: {$gte: startStripPeriod, $lte: endStripPeriod}},
        ],
      };
      return this._StripService.getStrips(params);
    }
    // if it's an ecg Event, get the strips that are saved under that event (multiple strips)
    if (item.category === "ECG Event") {
      const endStripPeriod = DateTime.fromISO(item.timestamp).toUTC().toFormat("yyyy-MM-dd HH:mm:ss");
      // Fetch strips from this event plus any strips before this event
      const params = {
        studyId: item.studyId,
        $or: [{startTime: {$lte: endStripPeriod}}, {eventId: item.id}],
      };
      return this._StripService.getStrips(params);
    }
    return Promise.resolve([]);
  }

  _getSerIfNeeded(item) {
    if (item.category === "Report" || (item.category === "ECG Event" && !item.isTriageItem)) {
      return this._GeneratedReportService
        .getGeneratedReports({
          studyId: item.studyId,
          reportType: "Single Episode",
          includeStrips: true,
        })
        .then(({reports}) => reports);
    }
    return Promise.resolve([]);
  }

  _getChannelConnectionsIfNeeded(item) {
    if (item.category === "Notification" && item.title === "Lead Disconnected") {
      return this._ChannelConnectionService.getConnectionHistory({enrollmentId: item.enrollmentId});
    }
    return Promise.resolve([]);
  }

  _getUserFullName(userId) {
    let userFullName = "another user";
    if (userId) {
      return this._UserService
        .getUsers({id: userId})
        .then((response) => {
          if (response[0] && response[0].fullName) {
            userFullName = response[0].fullName;
          }
          return userFullName;
        })
        .catch(() => userFullName);
    }
    return Promise.resolve(userFullName);
  }

  async _getRequireReportIfNeeded(item) {
    if (!this.features.configureRequiredReportGeneration || !["ECG Event"].includes(item.category)) {
      return false;
    }
    const facilityStudyConfiguration = await this._FacilityService.getFacilityStudyConfigurations(
      item.facilityId
    );
    const eventType = this._EventService.getEcgEventType(item.type);
    return facilityStudyConfiguration?.requiredReportGeneration[eventType] === true;
  }

  _httpGet(url, params, headers = {}) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        ...headers,
        Authorization: authHeader,
      },
    });
  }
}
